
import { defineComponent, PropType } from 'vue';
import Base from '@/mixins/Base.vue';

export default defineComponent({
    mixins: [Base],
    data() {
        return {
            isEditing: false,
            newValue: null,
        };
    },
    methods: {
        openEditMode() {
            this.isEditing = true;
            this.newValue = structuredClone(this.modelValue);
        },
        save() {
            this.$emit('update:modelValue', this.newValue);
            this[this.method](this.url, this.params)
                .then(() => {
                    this.isEditing = false;
                });
        },
    },
    props: {
        modelValue: {
            type: undefined as any | PropType<unknown> | PropType<Array<any>>,
            default: undefined,
            required: true,
        },
        size: {
            type: String,
            default: null,
        },
        prefix: {
            type: String,
            default: null,
        },
        suffix: {
            type: String,
            default: null,
        },
        name: {
            type: String,
            default: null,
            required: true,
        },
        url: {
            type: String,
            default: null,
            required: true,
        },
        method: {
            type: String,
            default: null,
            required: true,
        },
        params: {
            type: Object,
            default: () => {
            },
            required: false,
        },
    },
});
