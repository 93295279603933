import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, renderSlot as _renderSlot, Fragment as _Fragment, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_o_button = _resolveComponent("o-button")!
  const _component_o_input = _resolveComponent("o-input")!
  const _component_o_field = _resolveComponent("o-field")!
  const _component_vee_field = _resolveComponent("vee-field")!
  const _component_vee_form = _resolveComponent("vee-form")!

  return (_openBlock(), _createBlock(_component_vee_form, {
    ref: "dropdown",
    class: _normalizeClass([{ 'is-editing': _ctx.isEditing }, "is-editable"]),
    onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.save()))
  }, {
    default: _withCtx(({ meta }) => [
      (_ctx.isEditing)
        ? (_openBlock(), _createBlock(_component_vee_field, {
            key: 0,
            modelValue: _ctx.newValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newValue) = $event)),
            name: _ctx.name,
            rules: "required",
            "validate-on-mount": ""
          }, {
            default: _withCtx(({ errors, field, handleChange }) => [
              _createVNode(_component_o_field, {
                message: errors[0] ? errors[0] : null,
                variant: errors[0] ? 'danger' : null,
                class: "m-0",
                expanded: ""
              }, {
                default: _withCtx(() => [
                  (_ctx.prefix)
                    ? (_openBlock(), _createBlock(_component_o_button, {
                        key: 0,
                        size: _ctx.size,
                        variant: "static"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.prefix), 1)
                        ]),
                        _: 1
                      }, 8, ["size"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_o_input, {
                    disabled: _ctx.loading,
                    "model-value": field.value,
                    name: _ctx.name,
                    size: _ctx.size,
                    style: { minWidth: 120 + 'px' },
                    expanded: "",
                    "onUpdate:modelValue": handleChange
                  }, null, 8, ["disabled", "model-value", "name", "size", "onUpdate:modelValue"]),
                  (_ctx.suffix)
                    ? (_openBlock(), _createBlock(_component_o_button, {
                        key: 1,
                        size: _ctx.size,
                        variant: "static"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.suffix), 1)
                        ]),
                        _: 1
                      }, 8, ["size"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_o_button, {
                    disabled: _ctx.loading || !meta.valid,
                    size: _ctx.size,
                    "icon-right": "check",
                    inverted: "",
                    "native-type": "submit",
                    variant: "success"
                  }, null, 8, ["disabled", "size"]),
                  _createVNode(_component_o_button, {
                    disabled: _ctx.loading,
                    size: _ctx.size,
                    "icon-right": "times",
                    inverted: "",
                    variant: "danger",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isEditing = false))
                  }, null, 8, ["disabled", "size"])
                ]),
                _: 2
              }, 1032, ["message", "variant"])
            ]),
            _: 2
          }, 1032, ["modelValue", "name"]))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            onClick: _cache[2] || (_cache[2] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.openEditMode && _ctx.openEditMode(...args)), ["self"]))
          }, [
            (_ctx.prefix)
              ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(_ctx.prefix), 1))
              : _createCommentVNode("", true),
            (_ctx.modelValue)
              ? _renderSlot(_ctx.$slots, "default", { key: 1 })
              : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                  _createTextVNode("-")
                ], 64)),
            (_ctx.suffix)
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.suffix), 1))
              : _createCommentVNode("", true)
          ]))
    ]),
    _: 3
  }, 8, ["class"]))
}